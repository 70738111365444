@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@100;300;400;500;700&display=swap');
@import url("https://use.typekit.net/ero2hwv.css");

body {
  font-family: Arial, sans-serif;
  margin: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
  height: 100vh;
}
